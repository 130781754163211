/* eslint-disable @angular-eslint/no-input-rename */
import {
  AksiaValidatorFn,
  IValidationService,
  IValidationId,
  IValidationRequest,
  VALIDATION_SERVICE_TOKEN,
  StateEnum,
  TValidators,
} from '@aksia/infrastructure';
import {
  afterNextRender,
  afterRender,
  computed,
  DestroyRef,
  Directive,
  effect,
  inject,
  Injector,
  input,
  model,
  runInInjectionContext,
  Signal,
  signal,
  untracked,
  WritableSignal,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UiControlDirective } from './ui-control.directive';

@Directive({
  selector: '[uivalidation]',
  standalone: true,
})
export class UiValidationDirective<T = unknown> {
  //#region Injections & Effects

  private readonly route = inject(ActivatedRoute);
  private readonly injector = inject(Injector);
  private readonly destroyRef = inject(DestroyRef);

  private readonly validationService: IValidationService =
    inject(this.route.snapshot.data['store'] ?? VALIDATION_SERVICE_TOKEN) ??
    undefined;
  private readonly valueEffect = effect(() => {
    let value = this.value();
    if (this.initialValue === Symbol.for('Not Set')) {
      this.initialValue = value;
    }
    untracked(() => {
      this.validate();
    });
  });

  //#endregion

  //#region Inputs/Outputs

  /* modelValidationId = model<number | IValidationId | undefined>();

  validationTag = input(undefined, {
    alias: 'validation.tag',
    transform: (tag: string | undefined) => {
      this.modelValidationTag.set(tag);
      return tag;
    },
  });

  modelValidationTag = model<string | undefined>(); */

  validators = input([], {
    transform: (validators: TValidators) => {
      let validatorsFn =
        typeof validators === 'function' ? validators() : validators;
      return validatorsFn;
    },
  });

  validationId = input(undefined, {
    alias: 'validation.id',
    transform: (validationId: number | string | undefined) => {
      return validationId ? `${validationId}` : '';
    },
  });

  validationChain = input<Array<string>>([], { alias: 'validation.chain' });

  //#endregion

  //#region Properties

  host?: UiControlDirective;
  initialValue: unknown | Symbol = Symbol.for('Not Set');
  value: WritableSignal<T | undefined> = signal(undefined);
  errors: WritableSignal<Array<string>> = signal([]);
  warnings: WritableSignal<Array<string>> = signal([]);
  state: Signal<StateEnum | undefined> = signal(undefined);

  calculatedState = computed(() =>
    (this.state?.() ?? this.errors?.()?.length! > 0)
      ? StateEnum.Error
      : this.warnings?.()?.length! > 0
        ? StateEnum.Warning
        : this.initialValue !== this.value()
          ? StateEnum.Dirty
          : StateEnum.Pristine,
  );

  //#endregion

  constructor() {
    afterNextRender(() => {
      this.registerValidation();
      this.validate();
    });
    this.destroyRef.onDestroy(() => this.onDestroy());
  }

  //#region Methods

  registerValidation() {
    if (
      this.validationService &&
      this.host?.tag() &&
      this.validators()?.length > 0
    ) {
      let results = this.validationService.registerValidation(
        this.validationId()
          ? `${this.host?.tag()}@${this.validationId()}`
          : this.host?.tag(),
        this.value,
        this.validators(),
        this.validationChain(),
      );

      runInInjectionContext(this.injector, () => {
        let validationServiceErrorEffect = effect(() => {
          this.errors.set(results.errors());
        });

        let validationServiceWarningEffect = effect(() => {
          this.warnings.set(results.warnings());
        });
      });
    }
  }

  validate() {
    let errors: Array<string> = [];
    let warnings: Array<string> = [];

    if (
      this.validationService &&
      this.host?.tag() &&
      this.validators()?.length > 0
    ) {
      //Stateful Validation
      if (!this.validationService.validationExists(this.host?.tag())) {
        this.registerValidation();
      }

      this.validationService.validate(
        this.validationId()
          ? `${this.host?.tag()}@${this.validationId()}`
          : this.host?.tag(),
        this.value(),
      );
    } else {
      //Stateless Validation
      this.validators()?.forEach((validator) => {
        let result = validator(this.value());
        errors.push(...result.errors);
        warnings.push(...result.warnings);
        this.warnings.set(warnings);
        this.errors.set(errors);
      });
    }
  }

  onDestroy() {
    console.log((this.host as any).value());
  }

  //#endregion
}
