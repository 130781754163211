import { InjectionToken, Type } from '@angular/core';
import {
  IAuthenticationService,
  IValidationService,
  ILayoutService,
  IEnvironment,
  INavigationService,
  ILoadingService,
  IModalService,
} from '../interfaces';
import { TViewItem, TViewMap } from './view.types';

export const ENVIRONMENT_TOKEN: InjectionToken<IEnvironment> =
  new InjectionToken('EnvironmentToken');

export const NAVIGATION_SERVICE_TOKEN: InjectionToken<INavigationService> =
  new InjectionToken('NavigationServiceToken', {
    providedIn: 'root',
    factory: () => undefined,
  });

export const LOADING_SERVICE_TOKEN: InjectionToken<ILoadingService> =
  new InjectionToken('LoadingServiceToken', {
    providedIn: 'root',
    factory: () => undefined,
  });

export const AUTHENTICATION_SERVICE_TOKEN: InjectionToken<IAuthenticationService> =
  new InjectionToken('AuthenticationServiceToken', {
    providedIn: 'root',
    factory: () => undefined,
  });

export const LAYOUT_SERVICE_TOKEN: InjectionToken<
  ILayoutService<
    unknown,
    unknown,
    TViewItem<unknown>,
    TViewMap<unknown, unknown>
  >
> = new InjectionToken('LayoutServiceToken', {
  providedIn: 'root',
  factory: () => undefined,
});

export const VALIDATION_SERVICE_TOKEN: InjectionToken<IValidationService> =
  new InjectionToken('ValidationServiceToken', {
    providedIn: 'root',
    factory: () => undefined,
  });

export const ORCHESTRATION_TOKEN: InjectionToken<boolean> = new InjectionToken(
  'OrchestrationToken',
  {
    providedIn: 'root',
    factory: () => false,
  },
);

export const ASSETS_TOKEN: InjectionToken<
  Map<string, { assetPath: string; assetData: unknown }>
> = new InjectionToken('AssetsToken', {
  providedIn: 'root',
  factory: () => undefined,
});

export const VIEW_MAP_TOKEN: InjectionToken<TViewMap<unknown, unknown>> =
  new InjectionToken('viewMapToken');

export const MODAL_SERVICE_TOKEN: InjectionToken<IModalService> =
  new InjectionToken('ModalServiceToken', {
    providedIn: 'root',
    factory: () => undefined,
  });

export const MODAL_PERMISSION_TOKEN: InjectionToken<Type<any>> =
  new InjectionToken('ModalPermissionToken');

export const INPUT_DIRECTIVE_TOKEN: InjectionToken<Type<any>> =
  new InjectionToken('InputDirectiveToken', {
    providedIn: 'root',
    factory: () => undefined,
  });
