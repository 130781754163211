@if (permDir.uipermission() !== PERMISSIONS.NONE || permDir.editPermissions()) {
  <div class="radio-group">
    @for (option of plainOptions(); track option.value) {
      <div class="radio">
        <input
          type="radio"
          [id]="option.value"
          [value]="option.value"
          [disabled]="option.disabled"
          [checked]="value() === option.value"
          [style.width]="radioSize()"
          [style.height]="radioSize()"
          (change)="updateRadioValue(option.value)"
        />
        <label [for]="option.value">{{ option.label }}</label>
      </div>
    }
  </div>
  <!-- @if (label()) {
  <label
    [label]="labelFormatted()"
    [labelTip]="labelTip()"
    [state]="state()"
  ></label>
} -->
  <div uitoolstrip>
    <ng-content #uiToolsMarkupRef select="[uitool]"></ng-content>
    <ng-template #uiToolsCodeRef></ng-template>
  </div>
}
