import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Output,
  ViewChild,
  WritableSignal,
  computed,
  effect,
  inject,
  input,
  output,
  signal,
  viewChildren,
} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import {
  CommonModule,
  CurrencyPipe,
  DecimalPipe,
  PercentPipe,
} from '@angular/common';
import { GetByRowColPipe } from '../../../pipes/get-by-row-col.pipe';
import {
  AksiaValidatorFn,
  ISpreadsheetSettings,
  IUiSpreadSheetCell,
  IValidationId,
  numericExp,
  PropExtend,
  SPREADSHEET_ALLOWED_VALUES,
  SpreadsheetSettings,
  UiSpreadSheetCell,
  UTILS,
} from '@aksia/infrastructure';
import { UiValidationDirective } from '../../../directives/ui/ui-validation.directive';
import { UiControlDirective } from '../../../directives/ui/ui-control.directive';

//#region Enums, Types & Consts

const allowedValues = {
  numeric: numericExp,
  alpha: /^[a-z]*$/i,
  all: /^[a-z0-9]*$/i,
};

const allowedKeys = [
  ...UTILS.CONSTANTS.SIGNED_DECIMALS,
  ...UTILS.CONSTANTS.NAVIGATION_KEYS,
  ...UTILS.CONSTANTS.EDIT_KEYS,
  'b',
  'm',
  't',
  'c',
  'v',
];

const ELEMENT_TYPE = {
  TD: 'td',
  INPUT: 'input',
};
type ElementType = keyof typeof ELEMENT_TYPE;

//#endregion

@Component({
  selector: 'spreadsheet',
  hostDirectives: [
    { directive: UiValidationDirective, inputs: ['validators'] },
  ],
  imports: [CommonModule, UiValidationDirective, GetByRowColPipe],
  templateUrl: './spreadsheet.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpreadsheetComponent extends UiControlDirective {
  //#region Injections

  private clipboard = inject(Clipboard);
  private readonly uiValidationDir = inject(UiValidationDirective, {
    self: true,
  });

  //#endregion

  //#region Host Bindings

  /* @HostBinding('attr.uicontrol')
  uiControl = ''; */

  //#endregion

  //#region Inputs/Outpus

  override settings = input(new SpreadsheetSettings(), {
    transform: (value: ISpreadsheetSettings) => {
      if (this.cells().length === 0) {
        this.generateCells(
          value.columnTitles ?? SpreadsheetSettings.defaultColumnTitles,
          value.rowTitles ?? SpreadsheetSettings.defaultRowTitles!,
          value.tagPrefix,
        );
        this.generateSummaryCells(
          value.columnTitles ?? SpreadsheetSettings.defaultColumnTitles,
          value.rowTitles ?? SpreadsheetSettings.defaultRowTitles!,
        );
      }
      let settings = Object.assign(new SpreadsheetSettings(), value);
      super.initSettings(settings);
      this.onSettingsUpdate();
      return settings;
    },
  });

  data = input([], {
    transform: (data: Array<unknown>) => {
      this.bindDataToCells(data, this.settings().valueField);
      return data;
    },
  });

  dataSummary = input(undefined, {
    transform: (data: Array<unknown>) => {
      this.bindDataToSummaryCells(data, this.settings().valueField);
      return data;
    },
  });

  dataValidationGroupId = input<number | undefined>();

  dataValidators = input(undefined, {
    transform: (
      validators:
        | undefined
        | ((cellDataItem: PropExtend<unknown>) => Array<AksiaValidatorFn>),
    ) => {
      return validators;
    },
  });

  @Output() cellSelected: EventEmitter<Array<IUiSpreadSheetCell>> =
    new EventEmitter();

  valueChange = output<unknown>();

  //#endregion

  //#region Props

  protected cells: WritableSignal<Array<UiSpreadSheetCell>> = signal([]);
  protected summaryCells: WritableSignal<Array<UiSpreadSheetCell>> = signal([]);
  public firstMarkedCell?: IUiSpreadSheetCell;
  public lastMarkedCell?: IUiSpreadSheetCell;
  private allowedValueRegExp!: RegExp;
  private keymap: any = {
    td: {
      ArrowLeft: (item: IUiSpreadSheetCell, e: KeyboardEvent) =>
        this.updateMarked(0, -1, e),
      ArrowRight: (item: IUiSpreadSheetCell, e: KeyboardEvent) =>
        this.updateMarked(0, 1, e),
      ArrowUp: (item: IUiSpreadSheetCell, e: KeyboardEvent) =>
        this.updateMarked(-1, 0, e),
      ArrowDown: (item: IUiSpreadSheetCell, e: KeyboardEvent) =>
        this.updateMarked(1, 0, e),
      Enter: (item: IUiSpreadSheetCell, e: KeyboardEvent) =>
        this.updateMarked(1, 0, e),
      Tab: (item: IUiSpreadSheetCell, e: KeyboardEvent) =>
        this.updateMarked(0, e.shiftKey ? -1 : 1, e),
      Home: (item: IUiSpreadSheetCell, e: KeyboardEvent) =>
        this.updateMarked(0, -item.columnIndex!, e),
      End: (item: IUiSpreadSheetCell, e: KeyboardEvent) =>
        this.updateMarked(
          0,
          this.settings().defaultColumnQuantity! - 1 - item.columnIndex!,
          e,
        ),
      Delete: (item: IUiSpreadSheetCell, e: KeyboardEvent) =>
        this.deleteValues(),
      c: (item: IUiSpreadSheetCell, e: KeyboardEvent) => this.copy(e),
      v: (item: IUiSpreadSheetCell, e: ClipboardEvent) => this.paste(e),
      Other: (item: IUiSpreadSheetCell, e: KeyboardEvent) =>
        this.editCell(item, e.key),
    },
    input: {
      Enter: (item: IUiSpreadSheetCell, e: KeyboardEvent) =>
        this.updateMarked(1, 0, e, true),
      Escape: (item: IUiSpreadSheetCell, e: KeyboardEvent) =>
        this.undoEdit(e.target as HTMLInputElement, item),
      Tab: (item: IUiSpreadSheetCell, e: KeyboardEvent) =>
        this.updateMarked(0, e.shiftKey ? -1 : 1, e),
      m: (item: IUiSpreadSheetCell, e: KeyboardEvent) =>
        this.multiplyValue(e, item, 'm'),
      b: (item: IUiSpreadSheetCell, e: KeyboardEvent) =>
        this.multiplyValue(e, item, 'b'),
      t: (item: IUiSpreadSheetCell, e: KeyboardEvent) =>
        this.multiplyValue(e, item, 't'),
      Other: (item: IUiSpreadSheetCell, e: KeyboardEvent) => {
        return;
      },
    },
  };
  private static currencyPipe = new CurrencyPipe('en-US');
  private static percentPipe = new PercentPipe('en-US');
  private static decimalPipe = new DecimalPipe('en-US');

  //#endregion

  //#region View Children

  @ViewChild('uiValueRef') uiValueRef!: ElementRef;
  validations = viewChildren<UiValidationDirective>(UiValidationDirective);

  //#endregion

  //#region Functions
  constructor() {
    super();
    const validationEffector = effect(() => {
      this.validations()?.forEach((validation) => {
        /* let cell = this.cells()?.find(
          (cell) =>
            cell.dataItem?.[this.settings()?.idField!] ===
            (validation.modelValidationId() as IValidationId)?.id,
        );
        if (cell) {
          cell.state = validation.calculatedState;
          if (cell.dataItem?.[this.settings().valueField!]) {
            validation.updateValidationValue(
              cell.dataItem?.[this.settings().valueField!],
            );
          }
        } */
      });
      validationEffector.destroy();
    });
  }

  protected onSettingsUpdate() {
    this.allowedValueRegExp =
      allowedValues[
        this.settings().allowedValues ?? SPREADSHEET_ALLOWED_VALUES.Numeric
      ];
  }
  private generateCells(
    columnTitles: Array<string>,
    rowTitles: Array<string>,
    tagPrefix?: string,
  ) {
    this.cells.set([]);
    rowTitles?.forEach((rowTitle, rowIndex) => {
      columnTitles?.forEach((colTitle, colIndex) => {
        let cell = new UiSpreadSheetCell();
        cell.columnIndex = colIndex;
        cell.rowIndex = rowIndex;
        cell.tag = `${tagPrefix ?? 'tag'} ${colTitle}-${rowTitle}`;
        cell.classes = computed(() => 'text-right justify-center');
        this.cells.update((cells) => {
          cells.push(cell);
          return cells;
        });
      });
    });
  }

  private generateSummaryCells(
    columnTitles: Array<string>,
    rowTitles: Array<string>,
  ) {
    let colIndex = columnTitles.length;
    this.summaryCells.set([]);
    rowTitles?.forEach((rowTitle, rowIndex) => {
      let cell = new UiSpreadSheetCell();
      cell.columnIndex = colIndex;
      cell.rowIndex = rowIndex;
      cell.classes = computed(() => 'text-right justify-center');
      this.summaryCells.update((summaryCells) => {
        summaryCells.push(cell);
        return summaryCells;
      });
    });
  }

  private bindDataToCells(data: Array<unknown>, valueField?: string) {
    if (data) {
      let dataItemIsObject = data?.some((item) => UTILS.OBJECT.isObject(item));

      if ((dataItemIsObject && valueField) || !dataItemIsObject) {
        this.cells.update((cells) => {
          cells.forEach((cell, index) => {
            cell.dataItem = data?.at(index);
            cell.formattedValue = computed(() =>
              this.formatValue(
                cell.dataItem?.[valueField!],
                this.settings().numberFormattingPrecision,
                cell.dataItem?.[this.settings().currencyField!],
              ),
            );
          });
          return cells;
        });
      }
    }
  }

  private bindDataToSummaryCells(data: Array<unknown>, valueField?: string) {
    if (data) {
      let dataItemIsObject = data?.some((item) => UTILS.OBJECT.isObject(item));

      if ((dataItemIsObject && valueField) || !dataItemIsObject) {
        this.summaryCells.update((summaryCells) => {
          summaryCells.forEach((cell, index) => {
            cell.dataItem = data?.at(index);
            cell.formattedValue = computed(() =>
              this.formatValue(
                cell.dataItem?.[valueField!],
                this.settings().numberFormattingPrecision,
                cell.dataItem?.[this.settings().currencyField!],
              ),
            );
          });
          return summaryCells;
        });
      }
    }
  }

  public updateValue(value: any, cell: UiSpreadSheetCell) {
    let plainValue = UTILS.OBJECT.isEmpty(value)
      ? undefined
      : this.settings().allowedValues !== SPREADSHEET_ALLOWED_VALUES.Numeric
        ? value
        : this.settings().numberFormattingType === 'percent'
          ? +value / 100
          : +value;

    if (cell.dataItem[this.settings().valueField!] !== plainValue) {
      cell.dataItem[this.settings().valueField!] = plainValue;
      cell.dataItem[this.settings().deletedField!] =
        this.settings().deletedField && UTILS.OBJECT.isEmpty(value);

      /* let validation = this.validations()?.find(
        (validation) =>
          (validation.modelValidationId() as IValidationId)?.id ===
          cell.dataItem.$uid,
      );

      if (validation) {
        validation.validate();
      } */

      this.valueChange.emit(value);
    }
    cell.editing = false;
  }

  protected deleteValues() {
    this.cells()?.forEach((cell: UiSpreadSheetCell) => {
      if (cell.marked || cell.selected) {
        this.updateValue(undefined, cell);
      }
    });
  }

  protected formatValue(
    value: string | number | undefined,
    precision: string = '1.0-2',
    currency: string = 'USD',
  ) {
    let formattedValue = undefined;
    if (!value) return formattedValue;

    switch (this.settings().numberFormattingType) {
      case 'currency':
        formattedValue = SpreadsheetComponent.currencyPipe.transform(
          value,
          currency,
          'symbol-narrow',
          precision,
        );
        break;
      case 'percent':
        formattedValue = SpreadsheetComponent.percentPipe.transform(
          value,
          precision,
        );
        break;
      default:
        formattedValue = SpreadsheetComponent.decimalPipe.transform(
          value,
          precision,
        );
    }
    return formattedValue ?? undefined;
  }

  protected toggleFormat() {
    this.settings().numberFormat = !this.settings().numberFormat;
  }

  protected editCell(cell: IUiSpreadSheetCell, value?: string) {
    cell.editing = true;
    setTimeout(() => {
      let input = cell.el?.firstElementChild;
      if (input instanceof HTMLInputElement) {
        input.focus();
        if (value) {
          input.value = value;
        }
      }
    }, 10);
  }

  protected copy(e: Event) {
    if (e instanceof KeyboardEvent && e.ctrlKey) {
      const selectedCells = this.cells()?.filter(
        (item) => item.selected || item.marked,
      );
      selectedCells?.sort(
        (a, b) => a.rowIndex! - b.rowIndex! || a.columnIndex! - b.columnIndex!,
      );
      let copyStream = '';
      selectedCells?.forEach((cell: UiSpreadSheetCell, index) => {
        copyStream =
          copyStream +
          `${cell.dataItem[this.settings().valueField!]}${
            !selectedCells[index + 1]
              ? ''
              : (selectedCells[index + 1] as UiSpreadSheetCell).columnIndex! -
                    cell.columnIndex! ===
                  1
                ? ' '
                : '\n'
          }`;
      });
      this.clipboard.copy(copyStream);
    }
    e.preventDefault();
  }

  protected paste(e: ClipboardEvent) {
    const rowSplit = '\n';
    const selectedCell = this.cells()?.find((item) => item.selected);

    if (selectedCell) {
      if (navigator.clipboard === undefined) return;
      navigator.clipboard.readText().then((clipText) => {
        const clipRows = clipText?.split(rowSplit).filter(Boolean);
        let rowItems;
        const colSplit = clipRows[0].includes('\t') ? '\t' : ' ';
        clipRows.forEach((row, rowIndex) => {
          rowItems = row?.split(colSplit);
          rowItems.forEach((value, colIndex) => {
            value = value.replace('\r', '');
            const item = this.cells()?.find(
              (item) =>
                item.columnIndex ===
                  (selectedCell as UiSpreadSheetCell).columnIndex! + colIndex &&
                (item as UiSpreadSheetCell).rowIndex ===
                  (selectedCell as UiSpreadSheetCell).rowIndex! + rowIndex,
            ) as PropExtend<UiSpreadSheetCell>;
            if (item) {
              let plainValue =
                this.settings().allowedValues === 'numeric'
                  ? this.settings().numberFormattingType === 'percent'
                    ? +value * 100
                    : +value
                  : value;
              this.updateValue(plainValue ?? undefined, item);
              item.el!.innerHTML = this.formatValue(value) ?? '';
            }
          });
        });
      });
    }
  }

  //#endregion

  //#region Key Events

  protected keydown(e: KeyboardEvent, cell: IUiSpreadSheetCell) {
    const elementType: ElementType = (e.target as HTMLElement)
      .localName as ElementType;
    if (this.keyAllowed(e.key) && elementType && this.keymap[elementType]) {
      if (typeof this.keymap[elementType][e.key] === 'function') {
        this.keymap[elementType][e.key](this.firstMarkedCell, e);
      } else {
        this.keymap[elementType].Other(this.firstMarkedCell, e);
      }
    } else {
      e.preventDefault();
      return;
    }
  }

  //#endregion

  //#region Mouse Events

  protected click(e: Event, cell: IUiSpreadSheetCell) {
    if (cell.editing) {
      e.preventDefault();
      return;
    }

    this.resetAllCells();
    cell.selected = true;

    this.updateMarked(
      cell.rowIndex! - (this.lastMarkedCell?.columnIndex ?? 0),
      cell.columnIndex! - (this.lastMarkedCell?.columnIndex ?? 0),
      e,
    );
  }

  protected clickHeader(
    e: MouseEvent,
    colIndex: number = -1,
    rowIndex: number = -1,
  ) {
    if (!(e.shiftKey || e.ctrlKey)) {
      this.resetAllCells();
    }

    const colItems = this.cells()?.filter(
      (item) =>
        ((item.columnIndex === colIndex || colIndex === -1) &&
          (item.rowIndex === rowIndex || rowIndex === -1)) ||
        (item.marked && (e.shiftKey || e.ctrlKey)),
    );
    this.firstMarkedCell = colItems?.at(0);
    this.firstMarkedCell!.selected = true;
    this.lastMarkedCell = colItems?.at(colItems.length - 1);
    colItems?.forEach((item) => (item.marked = true));
    this.cellSelected.emit(
      this.cells()?.filter((item) => item.marked || item.selected),
    );
  }

  protected startDrag(e: DragEvent, cell: IUiSpreadSheetCell) {
    if (cell.editing) {
      e.preventDefault();
      return;
    }

    this.resetAllCells();

    cell.selected = true;
    this.firstMarkedCell = cell;

    const proxyEl = (e.target as HTMLElement)?.cloneNode(true) as HTMLElement;
    proxyEl.style.opacity = '0';
    e.dataTransfer!.setDragImage(proxyEl, 0, 0);
  }

  protected enterDrag(cell: IUiSpreadSheetCell) {
    this.lastMarkedCell = cell;
    this.cells()?.forEach(
      (item) =>
        (item.marked = this.shouldBeMarked(item.columnIndex!, item.rowIndex!)),
    );
  }

  protected endDrag() {
    this.cellSelected.emit(
      this.cells()?.filter((item) => item.marked || item.selected),
    );
  }

  //#endregion

  //#region Private Methods

  private updateMarked(
    rowOffset: number,
    columnOffset: number,
    e: Event,
    forceFocus?: boolean,
  ) {
    const toItem = this.cells()?.find(
      (item) =>
        item.columnIndex ===
          (this.lastMarkedCell?.columnIndex ?? 0) + columnOffset &&
        item.rowIndex === (this.lastMarkedCell?.rowIndex ?? 0) + rowOffset,
    );
    if (toItem) {
      if ((e as KeyboardEvent).shiftKey) {
        this.lastMarkedCell = toItem;
        this.cells()?.forEach(
          (item) =>
            (item.marked = this.shouldBeMarked(
              item.columnIndex!,
              item.rowIndex!,
            )),
        );
      } else if ((e as KeyboardEvent).ctrlKey) {
        this.resetSelectedCell();
        this.firstMarkedCell = this.lastMarkedCell = toItem;
        toItem.selected = toItem.marked = true;
      } else {
        this.resetAllCells();
        toItem.selected = true;
        this.firstMarkedCell = this.lastMarkedCell = toItem;
      }
      this.cellSelected.emit(
        this.cells()?.filter((item) => item.marked || item.selected),
      );
    }

    let selectedCell = this.cells()?.find((item) => item.selected);
    this.cells()?.forEach(
      (item, i) =>
        (item.el = this.uiValueRef.nativeElement.querySelector(
          `td[data-index="${i}"]`,
        ) as HTMLElement),
    );
    let td = this.uiValueRef.nativeElement.querySelector(
      `td[data-index="${this.indexFromColumnRow(
        selectedCell!.columnIndex!,
        selectedCell!.rowIndex!,
      )}"]`,
    ) as HTMLElement;
    td?.focus();
    e.preventDefault();
  }

  private undoEdit(input: HTMLInputElement, cell: IUiSpreadSheetCell) {
    cell.editing = false;
    input.blur();
    //item.meta_undo!(item.metaCellValueProp!);
  }

  private multiplyValue(
    e: KeyboardEvent,
    cell: IUiSpreadSheetCell,
    multiplier: 'm' | 'b' | 't',
  ) {
    const el = e.target as HTMLInputElement;
    if (this.settings().allowedValues === 'numeric') {
      const value = el.value;
      const multiFactor =
        multiplier === 'm'
          ? 1_000_000
          : multiplier === 'b'
            ? 1_000_000_000
            : 1_000_000_000_000;
      const multipliedValue = +value * multiFactor;
      el.value = multipliedValue.toString();
      e.preventDefault();
    }
    return;
  }

  private resetAllCells() {
    this.cells().forEach((item) => {
      item.selected = false;
      item.marked = false;
      item.editing = false;
    });
    this.firstMarkedCell = this.lastMarkedCell = undefined;
  }

  private resetSelectedCell() {
    this.cells()?.forEach((item) => (item.selected = false));
    this.firstMarkedCell = this.lastMarkedCell = undefined;
  }

  private keyAllowed(key: string) {
    let isAllowed =
      allowedKeys.includes(key) || this.allowedValueRegExp.test(key);
    return isAllowed;
  }

  protected indexFromColumnRow(columnIndex: number, rowIndex: number) {
    return this.settings().columnTitles!.length * rowIndex + columnIndex;
  }

  protected shouldBeMarked(columnIndex: number, rowIndex: number) {
    return (
      (columnIndex! <=
        Math.max(
          this.firstMarkedCell?.columnIndex!,
          this.lastMarkedCell?.columnIndex!,
        ) ||
        columnIndex === -1) &&
      (columnIndex! >=
        Math.min(
          this.firstMarkedCell?.columnIndex!,
          this.lastMarkedCell?.columnIndex!,
        ) ||
        columnIndex === -1) &&
      (rowIndex! <=
        Math.max(
          this.firstMarkedCell?.rowIndex!,
          this.lastMarkedCell?.rowIndex!,
        ) ||
        rowIndex === -1) &&
      (rowIndex! >=
        Math.min(
          this.firstMarkedCell?.rowIndex!,
          this.lastMarkedCell?.rowIndex!,
        ) ||
        rowIndex === -1)
    );
  }

  //#endregion
}
