import {
  DestroyRef,
  Directive,
  ElementRef,
  HostBinding,
  WritableSignal,
  inject,
  input,
  model,
  signal,
} from '@angular/core';
import {
  ICONS,
  IUiControlSettings,
  IconType,
  UTILS,
  UiControlSettings,
  StateEnum,
  ROLES,
  PERMISSIONS,
} from '@aksia/infrastructure';
import { UiPermissionDirective } from './ui-permission.directive';

@Directive({
  selector: 'uicontrol',
  standalone: true,
  hostDirectives: [
    {
      directive: UiPermissionDirective,
      inputs: ['uipermission'],
    },
  ],
})
export class UiControlDirective {
  //#region Injections

  public hostElRef = inject(ElementRef);
  protected destroyRef = inject(DestroyRef);
  protected permDir = inject(UiPermissionDirective);

  //#endregion

  //#region Inputs/Outputs

  uid = input(UTILS.GENERATE.nextuid.toString(), {
    transform: (uid: string | number | undefined) => {
      return uid?.toString();
    },
  });

  settings = input<UiControlSettings, IUiControlSettings>(
    new UiControlSettings(),
    {
      transform: (settings: IUiControlSettings) => this.initSettings(settings),
    },
  );

  state = model<StateEnum>();

  headerCssClass = input<string | undefined>();
  contentCssClass = input<string | undefined>();
  footerCssClass = input<string | undefined>();

  //#endregion

  //#region Host Bindings

  @HostBinding('attr.uicontrol')
  uiControl = '';

  @HostBinding('attr.uifocused')
  get uiFocused() {
    return this.isFocused() ? '' : undefined;
  }

  @HostBinding('attr.uihidden')
  get uiHidden() {
    return this.isHidden() ? '' : undefined;
  }

  @HostBinding('attr.uidisabled') get uiDisabled() {
    return this.settings()?.disabled ? '' : undefined;
  }

  @HostBinding('attr.uilabel')
  get uiLabel() {
    return this.label() ?? undefined;
  }

  @HostBinding('attr.uistate') get uiState() {
    return this.state();
  }

  @HostBinding('attr.uitag') get uiTag() {
    return this.tag() ?? this.label() ?? undefined;
  }

  //#endregion

  //#region Properties

  protected readonly PERMISSIONS = PERMISSIONS;
  protected readonly ROLES = ROLES;
  protected readonly ICONS = ICONS;
  protected readonly isNil = UTILS.OBJECT.isNil;

  isFocused: WritableSignal<boolean> = signal<boolean>(false);
  isHidden: WritableSignal<boolean> = signal<boolean>(false);

  label: WritableSignal<string | undefined> = signal(undefined);
  labelFocused: WritableSignal<string | undefined> = signal(undefined);
  labelTip: WritableSignal<string | undefined> = signal(undefined);
  defaultIcon: WritableSignal<IconType | undefined> = signal(undefined);
  tag: WritableSignal<string> = signal('');
  tagGroup: WritableSignal<string> = signal('');

  //#endregion

  //#region Functions

  protected initSettings(settings: IUiControlSettings): IUiControlSettings {
    let controlSettings = UTILS.OBJECT.assign(
      new UiControlSettings(),
      settings,
    );
    this.isHidden.set(controlSettings.hide);
    this.label.set(controlSettings.label);
    this.labelFocused.set(controlSettings.labelFocused);
    this.labelTip.set(controlSettings.labelTip);
    this.defaultIcon.set(controlSettings.defaultIcon);
    this.tag.set(controlSettings.tag ?? controlSettings.label);
    this.tagGroup.set(controlSettings.tagGroup);
    return controlSettings;
  }

  protected focusin() {
    if (this.permDir.uipermission() !== PERMISSIONS.VIEW) {
      this.isFocused.set(true);
    }
  }

  protected focusout() {
    this.isFocused.set(false);
  }

  //#endregion
}
