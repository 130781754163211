import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  HostBinding,
  input,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../presentation/icon/icon.component';
import { LabelComponent } from '../../presentation/label/label.component';
import {
  TextSettings,
  ITextSettings,
  TextInputType,
  UTILS,
} from '@aksia/infrastructure';
import { UiInputDirective } from '../../../directives/ui/ui-input.directive';

@Component({
    selector: 'text',
    imports: [CommonModule, LabelComponent, IconComponent],
    templateUrl: './text.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextComponent extends UiInputDirective {

  //#region Inputs/Outputs

  override settings = input<TextSettings, ITextSettings>(new TextSettings(), {
    transform: (settings: ITextSettings) => this.initSettings(settings),
  });

  /* override value = model<string | undefined>(); */

  @Output() InputChanged: EventEmitter<string> = new EventEmitter<string>();

  //#endregion

  @HostBinding('class.disabled')
  get isDisabled() {
    return this.settings().disabled;
  }

  //#region Properties

  type: WritableSignal<TextInputType | undefined> = signal(
    this.settings().type,
  );
  multiline: WritableSignal<boolean | undefined> = signal(
    this.settings().multiline,
  );
  showCharCount: WritableSignal<boolean | undefined> = signal(
    this.settings().showCharCount,
  );
  valueLength = computed(() =>
    typeof this.value() === 'string' ? (this.value() as string).length : 0,
  );
  protected isExpanded = signal<boolean>(false);

  //#endregion

  //#region Functions

  constructor() {
    super();
    afterNextRender(() => {
      if(this.settings().startExpanded){
        this.toggleExpand(true);
      }
    });    
  }

  protected override initSettings(settings: ITextSettings) {
    let textSettings = UTILS.OBJECT.assign(new TextSettings(), settings);
    super.initSettings(textSettings);
    this.type.set(textSettings.type);
    this.multiline.set(textSettings.multiline);
    this.showCharCount.set(textSettings.showCharCount);
    return textSettings;
  }

  override focusin() {
    super.focusin();
  }

  override focusout() {
    this.updateValue(
      this.uiValueRef.nativeElement.value === ''
        ? undefined
        : this.uiValueRef.nativeElement.value,
    );
    super.focusout();
  }

  protected clearValue(event: MouseEvent) {
    event.stopImmediatePropagation();
    this.updateValue(undefined);
    this.toggleExpand(false);
  }

  protected emitInput(value: string) {
    if (this.InputChanged.observed) {
      this.InputChanged.emit(value);
    }
  }

  protected toggleExpand(expand: boolean) {
    this.isExpanded.set(expand);
    if (!this.isExpanded()) 
      this.uiValueRef.nativeElement.style.height = '20px';
    else this.updateSize();
  }

  updateSize(): void {    
    const textarea = this.uiValueRef.nativeElement;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 16 + 'px';

    if(textarea.scrollHeight > 24){
      this.isExpanded.set(true);
    }else{
      this.isExpanded.set(false);
    }

    if (textarea.scrollHeight > textarea.offsetHeight) {
      textarea.classList.add('scrollable');
    } else {
      textarea.classList.remove('scrollable');
    }    
  }

  //#endregion
}
