import {
  AksiaValidatorFn,
  IValidationService,
  StateEnum,
  TRegisteredValidation,
} from '@aksia/infrastructure';
import { computed, Injectable, Signal, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidationService implements IValidationService {
  registeredValidations: Map<string, TRegisteredValidation> = new Map<
    string,
    TRegisteredValidation
  >();

  hasErrors = signal(false);

  hasWarnings = signal(false);

  stateSummary = signal('');
  /* stateSummary = computed(() =>
    Array.from(this.registeredValidations.entries())
      .filter(
        (registeredValidation) =>
          registeredValidation?.[1].warnings()?.length > 0 ||
          registeredValidation?.[1].errors()?.length > 0,
      )
      ?.map(
        (registeredValidation) =>
          `${registeredValidation?.[0]}: ${registeredValidation?.[1].errors()?.length} errors, ${registeredValidation?.[1].warnings()?.length} warnings`,
      )
      ?.join('\n\r'),
  ); */

  state = computed(() =>
    this.hasErrors()
      ? StateEnum.Error
      : this.hasWarnings()
        ? StateEnum.Warning
        : StateEnum.Pristine,
  );

  validationExists(tag: string) {
    return this.registeredValidations.has(tag);
  }

  registerValidation(
    tag: string,
    value: Signal<unknown>,
    validators: Array<AksiaValidatorFn>,
    chainTags?: Array<string>,
  ) {
    this.registeredValidations.set(tag, {
      value,
      validators,
      chainTags: chainTags ?? [],
      errors: signal([]),
      warnings: signal([]),
    });

    return this.registeredValidations.get(tag)!;
  }

  removeValidation(tag: string) {
    if (tag.startsWith('@')) {
      let tags = this.registeredValidations
        .keys()
        ?.filter((k) => k.includes(tag));
      tags?.forEach((tag) => {
        this.registeredValidations.delete(tag);
      });
    } else {
      this.registeredValidations.delete(tag);
    }
  }

  validate(tag: string, value: unknown, preventChainValidation = false) {
    let registeredValidation = this.registeredValidations.get(tag);
    registeredValidation?.errors.set([]);
    registeredValidation?.warnings.set([]);
    registeredValidation?.validators.forEach((validator) => {
      let result = validator(registeredValidation.value());
      this.registeredValidations
        .get(tag)
        ?.errors.update((errors) => [...errors, ...result.errors]);
      this.registeredValidations
        .get(tag)
        ?.warnings.update((warnings) => [...warnings, ...result.warnings]);
    });

    if (!preventChainValidation) {
      registeredValidation?.chainTags.forEach((chainTag) => {
        let chainedValidation = this.registeredValidations.get(chainTag);
        this.validate(chainTag, chainedValidation?.value, true);
      });
    }

    this.updateState();

    return {
      errors: registeredValidation?.errors() ?? [],
      warnings: registeredValidation?.warnings() ?? [],
    };
  }

  private updateState() {
    this.hasErrors.set(
      Array.from(this.registeredValidations.entries()).some(
        (registeredValidation) =>
          registeredValidation?.[1].errors()?.length > 0,
      ),
    );

    this.hasWarnings.set(
      Array.from(this.registeredValidations.entries()).some(
        (registeredValidation) =>
          registeredValidation?.[1].warnings()?.length > 0,
      ),
    );

    this.stateSummary.set(
      Array.from(this.registeredValidations.entries())
        .filter(
          (registeredValidation) =>
            registeredValidation?.[1].warnings()?.length > 0 ||
            registeredValidation?.[1].errors()?.length > 0,
        )
        ?.map(
          (registeredValidation) =>
            `${registeredValidation?.[0]}: ${registeredValidation?.[1].errors()?.length} errors, ${registeredValidation?.[1].warnings()?.length} warnings`,
        )
        ?.join('\n\r'),
    );
  }
}
