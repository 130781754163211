import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  signal,
  untracked,
  viewChild,
  WritableSignal,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IconComponent } from '../../presentation/icon/icon.component';
import { UiPopupDirective } from '../../../directives/ui/ui-popup.directive';
import {
  DateSelectionType,
  IUiOption,
  IUiPopupSettings,
  UTILS,
} from '@aksia/infrastructure';
import { LabelComponent } from '../../presentation/label/label.component';
import {
  DATE_SELECTION,
  DateSettings,
  IDateSettings,
} from '@aksia/infrastructure';
import { UiInputDirective } from '../../../directives/ui/ui-input.directive';

@Component({
  selector: 'date',
  imports: [CommonModule, LabelComponent, IconComponent, UiPopupDirective],
  templateUrl: './date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateComponent extends UiInputDirective {
  //#region Inputs/Outputs

  override settings = input<DateSettings, IDateSettings>(new DateSettings(), {
    transform: (settings: IDateSettings) => this.initSettings(settings),
  });

  //#endregion

  //#region View Children

  uiPopupDir = viewChild('uiPopupRef', { read: UiPopupDirective });

  //#endregion

  //#region Properties

  popupSettings: WritableSignal<IUiPopupSettings> = signal(
    this.settings().popup!,
  );
  protected override valueFormatter(value: unknown) {
    return UTILS.OBJECT.isNil(value)
      ? ''
      : this.datePipe.transform(value as Date, this.format());
  }

  private readonly datePipe = new DatePipe('en-US');
  private readonly dayOptionTitles = UTILS.CONSTANTS.DAYS_TO_DDD.map(
    (d: string, i: number) => ({ label: d, value: i }),
  );
  private readonly daysInMonth = computed(() =>
    new Date(
      this.currentDate().getFullYear(),
      this.currentDate().getMonth(),
      1,
    ).getDay(),
  );
  private readonly daysOffset = computed(() =>
    Array.from(Array(this.daysInMonth()), (_, i) => ({
      label: '',
      value: undefined,
    })),
  );

  protected selection: WritableSignal<DateSelectionType> = signal(
    this.settings().selection!,
  );
  protected format: WritableSignal<string> = signal(this.settings().format!);
  protected yearsInPage: WritableSignal<number> = signal(
    this.settings().yearsInPage!,
  );
  protected minDate: WritableSignal<Date | undefined> = signal(
    this.settings().minDate,
  );
  protected maxDate: WritableSignal<Date | undefined> = signal(
    this.settings().maxDate,
  );
  private years = computed(() =>
    Array.from(
      Array(this.yearsInPage()),
      (_, i) =>
        this.currentDate().getFullYear() + this.yearsInPage() * this.page() + i,
    ),
  );
  private page: WritableSignal<number> = signal(0);

  public options = computed<Array<IUiOption> | undefined>(() => {
    let dateValue = UTILS.DATE.isDate(this.value())
      ? (this.value() as Date)
      : UTILS.DATE.toLocalDate(this.value() as string);
    switch (this.calendarContent()) {
      case DATE_SELECTION.Day: {
        return [
          ...this.dayOptionTitles,
          ...this.daysOffset(),
          ...Array.from(
            Array(UTILS.DATE.getMonthDays(this.currentDate())),
            (_, i) => ({
              label: `${i + 1}`,
              value: i + 1,
              isSelected:
                i + 1 === dateValue?.getDate() &&
                this.currentDate().getMonth() === dateValue?.getMonth() &&
                this.currentDate().getFullYear() === dateValue?.getFullYear(),
              isSelectable: this.isDateSelectable(
                new Date(
                  this.currentDate().getFullYear(),
                  this.currentDate().getMonth(),
                  i + 1,
                ),
              ),
            }),
          ),
        ];
      }
      case DATE_SELECTION.Month: {
        return Array.from(UTILS.CONSTANTS.MONTHS_TO_MMM, (month, i) => ({
          label: month,
          value: i,
          isSelected:
            i === dateValue?.getMonth() &&
            this.currentDate().getFullYear() === dateValue?.getFullYear(),
          isSelectable: this.isDateSelectable(
            new Date(this.currentDate().getFullYear(), i, 1),
          ),
        }));
      }
      case DATE_SELECTION.Year: {
        return this.years().map((year, i) => ({
          label: `${year}`,
          value: year,
          isSelected: year === dateValue?.getFullYear(),
          isSelectable: this.isDateSelectable(
            new Date(year, this.currentDate().getMonth(), 1),
          ),
        }));
      }
      default:
        return undefined;
    }
  });

  private isDateSelectable(date: Date) {
    if (!this.minDate() && !this.maxDate()) return true;
    else if (this.minDate() && date < this.minDate()!) return false;
    else if (this.maxDate() && date > this.maxDate()!) return false;
    return true;
  }

  protected calendarContent: WritableSignal<DateSelectionType | undefined> =
    signal(undefined);

  protected calendarTitle = computed(() => {
    switch (this.calendarContent()) {
      case DATE_SELECTION.Day: {
        return this.datePipe.transform(this.currentDate(), 'MMMM yyyy');
      }
      case DATE_SELECTION.Month: {
        return this.currentDate().getFullYear().toString();
      }
      case DATE_SELECTION.Year: {
        let startYear =
          this.currentDate().getFullYear() + this.yearsInPage() * this.page();
        let endYear = startYear + this.yearsInPage() - 1;
        return `${startYear} - ${endYear}`;
      }
      default:
        return '';
    }
  });

  public currentDate: WritableSignal<Date> = signal(new Date());

  //#endregion

  //#region Functions

  constructor() {
    super();
    effect(() => {
      untracked(() => {
        this.value();
      });
      if (this.value()) {
        if (UTILS.DATE.isDate(this.value()))
          this.currentDate.set(this.value() as Date);
        else this.currentDate.set(new Date(this.value() as string));
      } else {
        this.currentDate.set(new Date());
      }
    });
  }

  protected override initSettings(settings: IDateSettings) {
    let dateSettings = UTILS.OBJECT.assign(new DateSettings(), settings);
    dateSettings.defaultDate = settings.defaultDate;
    dateSettings.minDate = settings.minDate;
    dateSettings.maxDate = settings.maxDate;
    let dateFormat =
      settings.format ?? DateSettings.defaultFormat(settings.selection);
    dateSettings.placeholder = dateFormat;

    super.initSettings(dateSettings);
    this.selection.set(dateSettings.selection);
    this.format.set(dateFormat);
    this.yearsInPage.set(dateSettings.yearsInPage);

    if (dateSettings.defaultDate) {
      this.currentDate.set(dateSettings.defaultDate);
    }

    this.minDate.set(dateSettings.minDate);
    this.maxDate.set(dateSettings.maxDate);
    this.defaultIcon.set(dateSettings.defaultIcon);
    this.popupSettings.set(dateSettings.popup);
    this.popupSettings.update((popupSettings) => {
      popupSettings.uid = dateSettings.controlId;
      return popupSettings;
    });
    return dateSettings;
  }

  protected select(option?: IUiOption) {
    this.page.set(0);
    switch (this.calendarContent()) {
      case DATE_SELECTION.Day: {
        this.currentDate.update((currentDate: Date) => {
          return new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            (option?.value as number) ?? 1,
          );
        });
        break;
      }
      case DATE_SELECTION.Month: {
        this.currentDate.update((currentDate: Date) => {
          return new Date(
            currentDate.getFullYear(),
            ((option?.value as number) ?? 0) + 1,
            0,
          );
        });
        break;
      }
      case DATE_SELECTION.Year: {
        this.currentDate.update((currentDate: Date) => {
          return new Date(
            (option?.value as number) ?? 1,
            currentDate.getMonth(),
            currentDate.getDate(),
          );
        });
        break;
      }
    }
    if (this.calendarContent() === this.selection()) {
      super.updateValue(UTILS.DATE.toLocalISOString(this.currentDate()));
      this.uiPopupDir()?.hidePopup();
    } else {
      this.calendarContent.set(
        this.calendarContent() === DATE_SELECTION.Year
          ? DATE_SELECTION.Month
          : DATE_SELECTION.Day,
      );
      //this.uiPopupDir.freeze();
    }
  }

  protected scroll(dir: number) {
    this.page.set(this.page() + dir);
    switch (this.calendarContent()) {
      case DATE_SELECTION.Day: {
        this.currentDate.set(UTILS.DATE.addMonth(this.currentDate(), dir)!);
        break;
      }
      case DATE_SELECTION.Month: {
        this.currentDate.set(UTILS.DATE.addYear(this.currentDate(), dir)!);
        break;
      }
    }
  }

  protected selectCalendarContent() {
    this.page.set(0);
    switch (this.calendarContent()) {
      case DATE_SELECTION.Day: {
        this.calendarContent.set(DATE_SELECTION.Month);
        break;
      }
      case DATE_SELECTION.Month: {
        this.calendarContent.set(DATE_SELECTION.Year);
        break;
      }
    }
  }

  protected popupChanged(isVisible: boolean) {
    if (!isVisible) {
      this.focusout();
      this.calendarContent.set(undefined);
    } else {
      this.calendarContent.set(this.selection());
    }
  }

  protected toggleOptionsPopup() {
    if (!this.settings().disabled) {
      this.focusin();
      this.uiPopupDir()?.togglePopup(this.hostElRef.nativeElement);
    }
  }

  //#endregion
}
