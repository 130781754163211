import {
  IUiPopupSettings,
  UI_POPUP_HIDE_STRATEGY,
  UiPopupSettings,
} from '@aksia/infrastructure';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  input,
  NgZone,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';
import { Observable, Subject, fromEvent, takeUntil, skip } from 'rxjs';

@Directive({
  selector: '[UiPopup]',
  standalone: true,
})
export class UiPopupDirective {
  //#region Injections

  private hostEl = inject(ElementRef);
  private triggerEl!: HTMLElement;
  private ngZone = inject(NgZone);

  //#endregion

  //#region Inputs/Outputs

  settings = input<UiPopupSettings, IUiPopupSettings>(new UiPopupSettings(), {
    alias: 'UiPopup',
    transform: (settings: IUiPopupSettings) => this.initSettings(settings),
  });

  @Output() UiPopupVisibilityChanged: EventEmitter<boolean> = new EventEmitter(
    false,
  );

  //#endregion

  //#region Host Bindings

  @HostBinding('attr.UiPopup') UiPopupDirective = true;

  @HostBinding('style.display')
  get uiDisplay() {
    return this.currentDisplay();
  }

  //#endregion

  //#region Properties

  hideStrategy: WritableSignal<string> = signal(this.settings().hideStrategy!);
  skipOutsideClick = signal(this.settings().skipOutsideClick ?? 0);

  currentDisplay: WritableSignal<'none' | undefined> = signal('none');
  popUpListener$?: Observable<any>;
  popUpClosed$: Subject<boolean> = new Subject<boolean>();

  get isVisible(): boolean {
    return this.currentDisplay() !== 'none';
  }

  //#endregion

  //#region Functions

  protected initSettings(settings: IUiPopupSettings): IUiPopupSettings {
    this.hideStrategy.set(
      settings.hideStrategy ?? UiPopupSettings.defaultPopupHideStrategy,
    );
    this.skipOutsideClick.set(settings.skipOutsideClick ?? 0);
    return Object.assign(new UiPopupSettings(), settings);
  }

  public togglePopup(triggerEl: HTMLElement) {
    this.triggerEl = triggerEl;
    if (this.currentDisplay() === 'none') {
      this.currentDisplay.set(undefined);
      this.checkIsInViewport(triggerEl);
      this.attachCloseListener();
      this.UiPopupVisibilityChanged.emit(true);
    }
  }

  public hidePopup() {
    this.currentDisplay.set('none');
    this.popUpClosed$.next(true);
    this.popUpListener$ = undefined;
    this.UiPopupVisibilityChanged.emit(false);
  }

  private attachCloseListener() {
    if (!this.popUpListener$) {
      this.popUpListener$ = fromEvent(document, 'click');
      this.popUpListener$
        .pipe(takeUntil(this.popUpClosed$), skip(this.skipOutsideClick()))
        .subscribe((event: Event) => {
          const targetEl = event.target as HTMLElement;
          if (this.shouldClose(targetEl)) {
            this.currentDisplay.set('none');
            this.popUpClosed$.next(true);
            this.popUpListener$ = undefined;
            this.UiPopupVisibilityChanged.emit(false);
          }
        });
    }
  }

  private shouldClose(targetEl: HTMLElement) {
    let targetElIsNotChild =
      !this.hostEl.nativeElement.contains(targetEl) &&
      targetEl.getAttribute('uipopupoption') !==
        this.settings()?.uid?.toString();
    switch (this.hideStrategy()) {
      case UI_POPUP_HIDE_STRATEGY.ClickOutside:
        return targetElIsNotChild;
      case UI_POPUP_HIDE_STRATEGY.ClickTrigger:
        return targetEl === this.triggerEl;
      case UI_POPUP_HIDE_STRATEGY.ClickOutsideOrOption:
        return targetElIsNotChild || targetEl.hasAttribute('uipopupoption');
      case UI_POPUP_HIDE_STRATEGY.ClickOutsideOrTrigger:
        return targetElIsNotChild || targetEl === this.triggerEl;
      default:
        return true;
    }
  }

  private checkIsInViewport(control: HTMLElement) {
    this.ngZone.runOutsideAngular(() => {
      let bounding = control.getBoundingClientRect();

      /* if (this.justify() === 'auto') {
        let popupHeight =
          typeof this.height() === 'number'
            ? (this.height() as number)
            : this.maxHeight()!;
        const canBeBottom =
          bounding.bottom + popupHeight <=
          (window.innerHeight || document.documentElement.clientHeight);
        this.currentJustify.set(
          canBeBottom ? UI_JUSTIFY.Bottom : UI_JUSTIFY.Top,
        );
      }

      if (this.align() === 'auto') {
        let popupWidth =
          typeof this.width() === 'number'
            ? (this.width() as number)
            : control.getBoundingClientRect().width;
        const canBeLeft = bounding.right - popupWidth >= 0;
        this.currentAlign.set(canBeLeft ? UI_ALIGN.Left : UI_ALIGN.Right);
      } */
    });
  }

  //#endregion
}
