@import 'ckeditor5/ckeditor5.css';


/* User Agent Stylesheet resets */

.ck-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.ck-content h1,
.ck-content h2,
.ck-content h3 {
  font-weight: bold;
}

.ck-content h1,
.ck-content h2,
.ck-content h3,
.ck-content ol,
.ck-content ul {  
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ck-content h1 {
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
}

.ck-content h2 {
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
}

.ck-content h3 {
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.ck-content p {
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.ck-content ol,
.ck-content ul {
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-inline-start: 40px;
}

.ck-content a {
  text-decoration: underline;
  color: rgb(0, 0, 238);
}

.ck-content .table {
  padding: 0;
  box-sizing: border-box;
}

.ck-content .table .ck-editor__editable .ck-editor__nested-editable {
  box-sizing: content-box;
}

/* end reset */


/* remove footer add ballon */
.ck-balloon-panel_visible.ck-powered-by-balloon {
  display: none !important;
}